import { bootstrapActions } from '../components/actions.js'

export function sectionActions() {
    
    bootstrapActions(
        'section__header', 
        'action',
        getActionsCallbacks()
    )
        
    addEventListenerOnAddModBtn()

}

function getActionsCallbacks() {
    return {
        Open:   openActionCallback,
        Edit:   editActionCallback,
        Show:   showActionCallback,
        Hide:   hideActionCallback,
        Delete: deleteActionCallback,
    }
}

function openActionCallback(item) {
}

function editActionCallback(item) {
}

function showActionCallback(item) {
    item.parentElement.dataset.visible = 1
}

function hideActionCallback(item) {
    item.parentElement.dataset.visible = 0
}

function deleteActionCallback(item) {

    item = item.parentElement // item = section__header.parent 

    let lastSection = item.previousElementSibling;

    while (lastSection) {
                
        if (lastSection.classList.contains('section')) {
          break;
        }
      
        lastSection = lastSection.previousElementSibling;
    }

    if(lastSection) {

        lastSection.classList.add('active')
        lastSection.classList.add('show')
        
        let lastTab = document.querySelector(`[data-id="${lastSection.id.match(/\d+/)[0]}"]`,`.nav-item` )
        
        if(lastTab) {
            lastTab.querySelector(".nav-link").setAttribute("tabindex", "0");
            lastTab.querySelector(".nav-link").setAttribute("aria-selected", true);
            lastTab.querySelector(".nav-link").classList.add('active')
        }
        
    } else {

        let nextSection = item.nextElementSibling;

        while (nextSection) {
        
            if (nextSection.classList.contains('section')) {
              break;
            }
          
            nextSection = nextSection.nextElementSibling;
        }

        if(nextSection) {

            nextSection.classList.add('active')
            nextSection.classList.add('show') 

            let nextTab = document.querySelector(`[data-id="${nextSection.id.match(/\d+/)[0]}"]`,`.nav-item` )
            
            if(nextTab) {
                nextTab.querySelector(".nav-link").setAttribute("tabindex", "0");
                nextTab.querySelector(".nav-link").setAttribute("aria-selected", true);
                nextTab.querySelector(".nav-link").classList.add('active')
            }

        }

    }
        
    let tabItem = document.querySelector(`[data-id="${item.id.match(/\d+/)[0]}"]`,`.nav-item` )
    if(tabItem) {
        tabItem.remove()
    }        

    item.remove()
}

function addEventListenerOnAddModBtn() {
        
    document.addEventListener('DOMContentLoaded', () => {
            
        let items = document.getElementsByClassName("addmodule");
        Array.prototype.forEach.call(items, item => {
            
            item.addEventListener('click', async (e) => {
                
                e.preventDefault()

                let modal = {
            
                    type: 'html',
                    title: `${window.M.str.local_pages.page_view_addmodule}`,
                    size: 'xl',
                    async: {
                        method: 'GET',
                        url: `${item.href}`
                    }
            
                }
            
                M.theme_soon.events.emit('MODAL_OPEN', modal)

            })

        })
    })
}