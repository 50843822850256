export function bootstrapActions(itemClassName, actionClassName, callbackFuncs) {

  document.addEventListener('DOMContentLoaded', () => {

    let items = document.getElementsByClassName(itemClassName);

    Array.prototype.forEach.call(items, item => {

      if (item) {

        let action_elems = item.getElementsByClassName(actionClassName);

        Array.prototype.forEach.call(action_elems, action => {
          action.addEventListener('click', (e) => {
            onClickAction(e, action.dataset.name, action.href, item, callbackFuncs)
          })

        })
        
      }

    })
  
  })
    
}


const Actions = {
  Open:   onClickOpen,
  Edit:   onClickEdit,
  Show:   onClickShow,
  Hide:   onClickHide,
  Delete: onClickDelete,
  Move:   onClickMove,
}

function onClickAction(e, actionName, actionURL, item, callbackFuncs) {
  Actions[actionName](e, actionURL, item, callbackFuncs[actionName])
}

function onClickOpen(e, actionURL, item, callback) {
  callback(item)
}

function onClickEdit(e, actionURL, item, callback) {
  callback(item)
}

function onClickMove(e) { e.preventDefault() }

async function onClickShow(e, actionURL, item, callback) {

  e.preventDefault()

  await fetch(actionURL).
    then(response => response.json()).
    then(outcome => {
        if(!outcome.error) {
          callback(item)
        }
      }
    )

}

async function onClickHide(e, actionURL, item, callback) {

  e.preventDefault()

  await fetch(actionURL).
    then(response => response.json()).
    then(outcome => {
      if(!outcome.error) {
        callback(item)
      }
    }
    )
    
}

function onClickDelete(e, actionURL, item, callback) {

  e.preventDefault()

  let modal = {

    title: window.M.str.admin.confirmation,
    content: `<p>${window.M.str.local_pages.list_view_delete_page}</p>`,
    type: 'confirm',
    actions: [
      {
        text: 'Delete',
        name: 'delete',
        style: 'danger',
        fn: function ({ close }) {
          onClickDeleteHelper();
          close();
        }
      },
      {
        text: 'Cancel',
        name: 'close',
        style: 'outline-primary',
        fn: function ({ close }) {
          close();
        }

      }
    ]

  }
  
  let onClickDeleteHelper = async function () {
    const res = await fetch(actionURL).
    then(response => response.json()).
    then(outcome => { 
      if(!outcome.error) {
        callback(item)
      }
    })
  }

  window.M.theme_soon.events.emit('MODAL_OPEN', modal)

}