import { bootstrapActions } from '../components/actions.js'

export function pageActions() {

    bootstrapActions(
        `pagesoverview__item`, 
        `pagesoverview__item__action`,
        getActionsCallbacks()
    )
    
}

function getActionsCallbacks() {
    return {
        Open:   openActionCallback,
        Edit:   editActionCallback,
        Show:   showActionCallback,
        Hide:   hideActionCallback,
        Delete: deleteActionCallback,
    }
}

function openActionCallback(item) {
    
}

function editActionCallback(item) {

}

function showActionCallback(item) {
    item.dataset.published = 1
}

function hideActionCallback(item) {
    item.dataset.published = 0
}

function deleteActionCallback(item) {
    item.parentElement.remove()
}