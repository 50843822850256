import { bootstrapDraggable } from './../components/draggable.js'
import { bootstrapActions } from '../components/actions.js'

export function modActions() {

    bootstrapDraggable()

    bootstrapActions(
        'mod', 
        'action',
        getActionsCallbacks()
    )

}

function getActionsCallbacks() {
    return {
        Open:   openActionCallback,
        Edit:   editActionCallback,
        Show:   showActionCallback,
        Hide:   hideActionCallback,
        Delete: deleteActionCallback,
    }
}

function openActionCallback(item) {
}

function editActionCallback(item) {
}

function showActionCallback(item) {
    item.dataset.visible = 1
}

function hideActionCallback(item) {
    item.dataset.visible = 0
}

function deleteActionCallback(item) {
    item.remove();
}